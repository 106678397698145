<template>
    <div class="container">
        <div class="image">
            <img alt="manutencao" src="../assets/Manutencao.jpg">
        </div>
        <div class="box">
            <img alt="logo" width="200" src="../assets/logo.webp">
            <h1>Estaremos de volta brevemente.</h1>
            <p>Obrigado pela sua paciência.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Notice',
    props: {
        msg: String
    }
}
</script>

<style>
.box{
    padding: 10px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
    margin: auto;
    background: #505760;
}
h1, p {color: white; margin-top: 0;}
.image img { width: -webkit-fill-available; max-height: 500px; max-width: 400px; }
.container{ margin: auto; max-width: 800px; width: 90%;}
/*@media screen and (max-width: 1400px) { .container { width: 1200px;} }
@media screen and (max-width: 1000px) { .container { width: 800px;} }
@media screen and (max-width: 600px) { .container { width: 500px;} }
@media screen and (max-width: 500px) { .container { width: 300px;} }
@media screen and (max-width: 300px) { .container { width: 150px;} }*/
</style>